var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "projectSetting",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm.show
        ? _c(
            "Tabs",
            { attrs: { type: "card" } },
            [
              _c(
                "TabPane",
                { attrs: { label: "项目参考值" } },
                [
                  _c("relationProjectReferenceList", {
                    attrs: { TBaseProjectId: _vm.TBaseProjectId },
                  }),
                ],
                1
              ),
              _c(
                "TabPane",
                { attrs: { label: "危急值" } },
                [
                  _c("relationProjectCriticalList", {
                    attrs: { TBaseProjectId: _vm.TBaseProjectId },
                  }),
                ],
                1
              ),
              _c(
                "TabPane",
                { attrs: { label: "规则" } },
                [
                  _c("relationProjectRulesList", {
                    attrs: { TBaseProjectId: _vm.TBaseProjectId },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }